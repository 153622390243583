(function ($) {
  Drupal.behaviors.boutiqueX5PageFormatterV1 = {
    attach: function (context) {
      var $thisModule = $('.js-boutique-x5-page-formatter', context);
      var isMobile = parseInt(Unison.fetch.now().width) === parseInt(Unison.fetch.all().small);
      var $allChildren = $thisModule.find(' > div');
      var $voucher = $thisModule.find('.sp19-ppro-v1');
      var $storeBtn = $voucher.find('.sp19-ppro__storebtn');
      var $downloadBtn = $voucher.find('.sp19-ppro__dlbtn');
      var $voucherLabels = $voucher.find('.el_boutique_h3.el_boutique_dark.sp19-ppro__service-block');
      var generateClassNames = $thisModule.data('generate-classes');

      if ($storeBtn.length && $downloadBtn.length) {
        $downloadBtn.insertBefore($storeBtn);
      }

      updateVoucherLabelClasses();
      if (generateClassNames) {
        addParentClassnames();
      }
      updateCarouselClasses();

      if (isMobile) {
        var $imgWrapper = $voucher.find('.el_boutique_img_wrapper');
        var $voucherCopy = $voucher.find('.sp19-ppro__mod4-copy');

        if ($imgWrapper.length && $voucherCopy.length) {
          $imgWrapper.insertBefore($voucherCopy);
        }
      }

      function addParentClassnames() {
        $.each($allChildren, function (child_index, child_value) {
          var $child = $(child_value);

          $child.addClass('boutique-x5-mod' + (Number(child_index) + 1) + ' js-boutique-x5-mod' + (Number(child_index) + 1));
        });
      }

      function updateCarouselClasses() {
        var $mod3 = $thisModule.find('.js-boutique-x5-mod3');

        if ($mod3.length && isMobile) {
          $mod3.find('.slick-prev').addClass('hide');
        }
      }

      function updateVoucherLabelClasses() {
        if (isMobile) {
          $($voucherLabels[1]).addClass('free-in-store');
          $($voucherLabels[2]).addClass('instructions');
        } else {
          $($voucherLabels[0]).addClass('free-in-store');
          $($voucherLabels[1]).addClass('instructions');
        }
      }
    }
  };
})(jQuery);
